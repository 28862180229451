.print-application {
  padding: 30px 30px;
  border-radius: 5px;
  background-color: #fff;
  width: 400px;
  margin: 20px auto;
}
.print-button {
  background-color: #40a4f1;
  border: 1px solid #40a4f1;
  border-radius: 3px;
  font-size: 15px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  text-decoration: none;
}
.print-button:hover {
  background-color: #2899ef;
  border: 1px solid #2899ef;
  color: #fff;
}
.print-button-wrapper {
  text-align: center;
}
