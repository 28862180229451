
.home-section{
    margin-top: 30px;
}
.home-wrapper{
    border-radius: 5px;
    background-color: #fff;
    margin: 20px 20px;
    padding: 30px 30px;
}
.box{
    padding: 50px 30px;
margin: 10px;
position: relative;
overflow: hidden;
background: #fff;
box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
transition: all 0.3s ease-in-out;
border-radius: 8px;
z-index: 1;
}
.box-img{
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
  
}
.button-box-wrap{
    display: flex;
    flex-wrap: wrap;
}
.box-button{
    color: #1668a2;
    border-color: #1668a2;
    border-radius: 3px;
    font-size: 15px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: 10px 10px;
    border: 1px solid;
    margin: 10px 10px 10px 0;
    text-decoration: none;
}
.box-button:hover{
    background-color: #4557e5;
    border: 1px solid #4557e5;
  color: #fff;
}
.box-content{
    padding: 20px 15px;
    background-color: #a5cfec;
}
.box-content h3{
    font-size: 20px;
}
.alert-success{
    color: #3cab94;
background-color: #a2ded2;
border-radius: 5px;
padding: 10px 10px;
margin-bottom: 30px;
}
.alert-success p{
    margin-bottom: 0;
}
.alert-link{
    font-weight: 600;
    color: #2f8573;
    text-decoration: none;
}
.home-content{
    margin-bottom: 50px;
}
.box-head{
    color: #0c629e;
    text-transform: uppercase;
}

.card{
    padding: 50px 30px;
margin: 10px;
position: relative;
overflow: hidden;
background: #fff;
box-shadow: 0 0 29px 0 rgba(18, 66, 101, 0.08);
transition: all 0.3s ease-in-out;
border-radius: 8px;
z-index: 1;
}
.card-img{
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}
.card-box-wrap{
    display: flex;
    flex-wrap: wrap;
}
.card-button{
    color: #fff;
    background-color: #1668a2;
    border: 1px solid #1668a2;
    border-radius: 3px;
    font-size: 15px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    padding: 10px 20px;
    margin: 10px 10px 10px 0;
    text-decoration: none;
}
.card-button:hover{
    background-color: #4557e5;
    border: 1px solid #4557e5;
  color: #fff;
}
.card-content{
    padding: 20px 15px;
    background-color: #a5cfec;
}
.card-content h3{
    font-size: 20px;
}