.query-help-wrap{
    border-radius: 5px;
    background-color: #fff;
    margin: 40px 20px 20px 20px;
    padding: 14px 30px;
}
.query-img-wrap{
    margin: 20px 20px;
    padding: 30px 30px;
    background-color: #fff;
    border-radius: 5px;

}

.query-help-wrap h4{
    font-size: 20px;
margin-bottom: 0;
}