.success-wrapper {
  padding: 30px 30px;
  border-radius: 5px;
  background-color: #fff;
  width: 600px;
  margin: 20px auto;
}
.success-wrapper p {
  margin-bottom: 0;
  text-align: center;
}
.success-button-wrap {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}
.success-id {
  font-weight: 500;
}

.success-button-wrap a {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .success-wrapper {
    width: 80%;
  }
}
