.navbar{
    background-color: #1668a2;
}
.nav-link{
    color: rgba(255, 255, 255, 0.65) !important;
}
.nav-menu-items{
    color: rgba(255, 255, 255, 0.65) !important;
padding: 10px 10px;
text-decoration: none;
display: flex;
align-items: center;
}
.nav-menu-items:hover{
    color: #fff !important;
}
.nav-menu-items svg{
    margin-right: 5px;
}