.download_pdf {
  background-color: #40a4f1;
  border: 1px solid #40a4f1;
  border-radius: 3px;
  font-size: 15px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  text-decoration: none;
}
.download_pdf:focus {
  box-shadow: none;
}
.hallticket_header {
  width: 90%;
  margin: 0 auto;
  border: 0.5px solid #d4d4d4;
}
.hallticket_header_img {
  padding: 5px 0;
}
.hallticket_header_img img {
  width: 100%;
}
.details_wrapper {
  width: 90%;
  margin: 0 auto;
  border-left: 0.5px solid #d4d4d4;
  border-right: 0.5px solid #d4d4d4;
  padding: 5px 10px;
  border-bottom: 0.5px solid #d4d4d4;
}
.details_wrapper h3 {
  font-weight: 500;
  font-size: 18px;
}
.student_info_appn_id {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}
.student_info_appn_id span {
  font-weight: 600;
}
.student_info {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.student_info_details {
  grid-area: 1 / 1 / 2 / 2;
}
.student_info_img {
  grid-area: 1 / 2 / 2 / 3;
}
.student_info_details_item {
  display: grid;
  grid-template-columns: 0.6fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: left;
}
.student_info_details_item_left {
  grid-area: 1 / 1 / 2 / 2;
  font-weight: 600;
}
.student_info_details_item_right {
  grid-area: 1 / 2 / 2 / 3;
}
.student_info_img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.student_qr_code {
  grid-area: 1 / 1 / 2 / 2;
}
.student_img {
  grid-area: 1 / 2 / 2 / 3;
}
.student_qr_code div img {
  width: 120px;
  height: 120px;
  object-fit: fill;
}
/* .student_img div {

} */
.student_img div img {
  /* width: 100%; */
  width: 100px;
  height: 130px;
  object-fit: fill;
}
.student_tick_table {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 0.7fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: left;
  border: 0.5px solid #d4d4d4;
  margin: 15px 0;
}

.student_tick_table_item1 {
  grid-area: 1 / 1 / 2 / 2;
  border-bottom: 0.5px solid #d4d4d4;
  border-right: 0.5px solid #d4d4d4;
}
.student_tick_table_item2 {
  grid-area: 1 / 2 / 2 / 3;
  border-bottom: 0.5px solid #d4d4d4;
  border-right: 0.5px solid #d4d4d4;
}
.student_tick_table_item3 {
  grid-area: 1 / 3 / 2 / 4;
  border-bottom: 0.5px solid #d4d4d4;
  border-right: 0.5px solid #d4d4d4;
}
.student_tick_table_item4 {
  grid-area: 1 / 4 / 2 / 5;
  border-bottom: 0.5px solid #d4d4d4;
}
.student_tick_table_item5 {
  grid-area: 2 / 1 / 3 / 2;
  border-right: 0.5px solid #d4d4d4;
}
.student_tick_table_item6 {
  grid-area: 2 / 2 / 3 / 3;
  border-right: 0.5px solid #d4d4d4;
}
.student_tick_table_item7 {
  grid-area: 2 / 3 / 3 / 4;
  border-right: 0.5px solid #d4d4d4;
}
.student_tick_table_item8 {
  grid-area: 2 / 4 / 3 / 5;
}
.student_tick_table > * {
  padding: 5px 5px 5px 10px;
}
.instructon_table_head {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  border-bottom: 0.5px solid #d4d4d4;
  padding-bottom: 10px;
  padding-top: 20px;
}
.instructon_table_list li {
  text-align: left;
  font-size: 12px;
  /* letter-spacing: 1px;
  line-height: 20px; */
  padding: 10px 0;
}
.student_extra {
  padding: 10px 0;
  border-bottom: 0.5px solid #d4d4d4;
  border-top: 0.5px solid #d4d4d4;
  margin: 15px 0;
}
