.guidelines-button{
    background-color: #3cab94;
border: 1px solid #3cab94;
border-radius: 3px;
font-size: 15px;
display: inline-block;
font-weight: 400;
text-align: center;
padding: 10px 15px;
color: #fff;
text-decoration: none;
}

.guidelines-button:hover{
    background-color: #359884;
border: 1px solid #359884;
color: #fff;
}
.guidelines-wrapper{
    border-radius: 5px;
    background-color: #fff;
    margin: 20px 20px;
    padding: 30px 30px;
}
.guidelines-wrapper h4{
    margin-bottom: 8px;
text-transform: uppercase;
letter-spacing: 0.02em;
font-size: 14px;
font-weight: 700;
}
.guidelines-wrapper p{
    color: #707070 ;
    margin-bottom: 30px;
    font-size: 14px;
}