.footer-wrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.footer-left{
margin-bottom: 0;
font-weight: 500;
}
.footer-right{
    color:#b5afaf;
margin-bottom: 0;

}
.footer{
background-color: #fff;
padding: 20px 0;
/* position: absolute;
    width: 100%;
    bottom: 0; */
}
