.verifier-wrapper {
  padding: 30px 30px;
  border-radius: 5px;
  background-color: #fff;
  margin: 20px 20px;
}

p.input-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}
p.input-response {
  font-size: 16px;
  text-transform: capitalize;
}
h4.verify-head {
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  text-transform: capitalize;
}
.print-button {
  background-color: #40a4f1;
  border: 1px solid #40a4f1;
  border-radius: 3px;
  font-size: 15px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  text-decoration: none;
}
.print-button:hover {
  background-color: #2899ef;
  border: 1px solid #2899ef;
  color: #fff;
}
.print-button-wrapper {
  text-align: center;
}
.input-image {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}
.input-image img {
  width: 100%;
}

.verify-head {
  margin-bottom: 25px;
}
