.register-wrapper {
  text-align: left;
  padding: 30px 30px;
  border-radius: 5px;
  background-color: #fff;
  margin: 20px 20px;
}
.register-button-primary {
  background-color: #40a4f1;
  border: 1px solid #40a4f1;
}
.register-button-primary:hover {
  background-color: #2899ef;
  border: 1px solid #2899ef;
}
.register-button-secondary {
  background-color: #1668a2;
  border: 1px solid #1668a2;
}
.register-button-secondary:hover {
  background-color: #4557e5;
  border: 1px solid #4557e5;
}
.form-group {
  margin: 20px 0;
}
.input-teach {
  margin-bottom: 20px;
}
.register-wrapper-top {
  display: flex;
  justify-content: space-between;
}
.register-wrapper-top-buttons a {
  margin: 0 20px;
}
.alert-info {
  color: #40a4f1;
  background-color: #b7ddfa;
  padding: 10px 10px;
  border-radius: 5px;
}
.bold-head {
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 14px;
  font-weight: 700;
  margin-top: 8px;
}
.bold-head a {
  color: #007bff;
  text-decoration: none;
}
.direction-right {
  direction: rtl;
}

.modal-image {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .register-wrapper-top {
    flex-direction: column;
  }
  .register-wrapper-top-buttons a {
    margin: 10px 20px 10px 0;
  }
}
